<ng-container *ngIf="isAdmin(); then adminPanel; else userPanel"></ng-container>

<!-- Template si usuario es Admin -->
<ng-template #adminPanel>
  <div>
    <nb-card>
      <nb-card-header>
        <h2>{{ titulo }}</h2>
      </nb-card-header>
      <nb-card-body
        [nbSpinner]="cargando"
        nbSpinnerStatus="primary"
        nbSpinnerSize="large"
        nbSpinnerMessage="Cargando"
        style="min-height: 100px;"
      >
        <div class="row" style="margin: 1rem;">
          <app-chart style="width: 100%; height: 60vh;"></app-chart>
        </div>
      </nb-card-body>
      <!-- <nb-card-footer></nb-card-footer> -->
    </nb-card>
  </div>
</ng-template>

<!-- Template si usuario no es Admin -->
<ng-template #userPanel>
  <div class="container">
    <div class="row justify-content-center text-center">
      <nb-card class="text-center col-12">
        <nb-card-body>
          <h2 class="title">401<br />Acceso Denegado</h2>
          <br />
          <button
            nbButton
            fullWidth
            type="button"
            class="home-button"
            style="max-width: 30vw; cursor: pointer;"
            (click)="
              routerNavigateTo({
                path: '/dashboard/home'
              })
            "
          >
            Volver a Inicio
          </button>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</ng-template>

<div class="container">
  <div class="row header-container">
    <div class="logo-container">
      <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
        <nb-icon icon="menu-2-outline" class="menu-icon"></nb-icon>
      </a>
    </div>
    <div class="actions-container">
      <nb-actions size="small" fullWidth>
        <nb-action class="control-item">
          <nb-search type="rotate-layout"></nb-search>
        </nb-action>
        <nb-action icon="home-outline"></nb-action>
        <nb-action icon="person-outline"></nb-action>
        <nb-action [icon]="disabledIconConfig"></nb-action>
        <nb-action (click)="logout()" icon="power-outline"></nb-action>

        <!-- <nb-action icon="search-outline"></nb-action> -->
        <nb-action>
          Custom Action
        </nb-action>
      </nb-actions>
    </div>
  </div>
</div>
<div class="row text-center" style="padding: 1rem;">
  <div>
    <h6>Proyecto MISO 4202 - 202010</h6>
    <p>
      Sebastian Garcia &nbsp; | &nbsp; Cristian Sepulveda &nbsp; | &nbsp; Jenny
      Herrera &nbsp; | &nbsp; Steven Erira
    </p>
    <button
      ghost
      nbButton
      size="xsmall"
      href="https://github.com/seebgar/MISO4202-proyecto-frontend"
    >
      Source Code
    </button>
  </div>
</div>
